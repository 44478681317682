import Tokenomics from "../components/ Tokenomics";
import Banner from "../components/Banner";
import EcoSystem from "../components/EcoSystem";
import FootBullNFT from "../components/FootBullNFT";
import Introduction from "../components/Introduction";
import Footer from "../components/Layout/Footer";
import NavBar from "../components/Layout/NavBar";
import NFTCards from "../components/NFTs";
import Partner from "../components/Partners";
import PlateformPreview from "../components/PlateformPreview";
import RoadMap from "../components/RoadMap";
import Team from "../components/Team";

export default function Home() {
  return (
    <>
      <NavBar />
      <Banner />
      <Introduction />
      <EcoSystem  />
      <FootBullNFT />
      <NFTCards />
      <Tokenomics />
      <PlateformPreview />
      <RoadMap />
      <Team />
      <Partner />
      <Footer />
    </>
  );
}
